import "./App.css";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { phone } from "phone";
import Intercom from "@intercom/messenger-js-sdk";
import LanguageSelect from "./LanguageSelect";
import Modal from "./Modal";

function App() {
  const country_codes = [
    "au",
    "nz",
    "in",
    "hk",
    "bd",
    "id",
    "jp",
    "kr",
    "my",
    "mn",
    "sg",
    "th",
    "vn",
    "cr",
    "do",
    "mx",
    "pr",
    "us",
    "ca",
    "br",
    "co",
    "cz",
    "dk",
    "fr",
    "de",
    "hu",
    "is",
    "no",
    "pl",
    "ro",
    "sk",
    "se",
    "sa",
    "tw",
    "tr",
    "gb",
  ];

  const code2lang = [
    { code: "US", lang: "English", speakers: 1500 },
    { code: "CN", lang: "Mandarin", speakers: 1100 },
    { code: "MX", lang: "Spanish", speakers: 580 },
    { code: "SA", lang: "Arabic", speakers: 422 },
    { code: "IN", lang: "Hindi", speakers: 600 },
    { code: "FR", lang: "French", speakers: 280 },
    { code: "BR", lang: "Portuguese", speakers: 265 },
    { code: "RU", lang: "Russian", speakers: 258 },
    { code: "ID", lang: "Indonesian", speakers: 199 },
    { code: "DE", lang: "German", speakers: 135 },
    { code: "JP", lang: "Japanese", speakers: 128 },
    { code: "VN", lang: "Vietnamese", speakers: 95 },
    { code: "KR", lang: "Korean", speakers: 77 },
    { code: "TR", lang: "Turkish", speakers: 75 },
    { code: "TH", lang: "Thai", speakers: 70 },
    { code: "IT", lang: "Italian", speakers: 67 },
    { code: "PL", lang: "Polish", speakers: 50 },
    { code: "UA", lang: "Ukrainian", speakers: 40 },
    { code: "MY", lang: "Malay", speakers: 33 },
    { code: "RO", lang: "Romanian", speakers: 28 },
    { code: "NL", lang: "Dutch", speakers: 24 },
    { code: "GR", lang: "Greek", speakers: 13 },
    { code: "HU", lang: "Hungarian", speakers: 13 },
    { code: "CZ", lang: "Czech", speakers: 10 },
    { code: "SE", lang: "Swedish", speakers: 10 },
    { code: "FI", lang: "Finnish", speakers: 6 },
    { code: "DK", lang: "Danish", speakers: 6 },
    { code: "NO", lang: "Norwegian", speakers: 5 },
    { code: "BG", lang: "Bulgarian", speakers: 7 },
    { code: "LV", lang: "Latvian", speakers: 2 },
    { code: "LT", lang: "Lithuanian", speakers: 3 },
    { code: "EE", lang: "Estonian", speakers: 1.1 },
    { code: "MT", lang: "Maltese", speakers: 0.5 },
    { code: "HR", lang: "Croatian", speakers: 5 },
    { code: "SI", lang: "Slovenian", speakers: 2.5 },
    { code: "IE", lang: "Irish", speakers: 1.8 },
    { code: "MN", lang: "Mongolian", speakers: 5.2 },
    { code: "IL", lang: "Hebrew", speakers: 9 },
    { code: "IR", lang: "Persian", speakers: 80 },
    { code: "HK", lang: "Cantonese", speakers: 85 },
    { code: "ZZ", lang: "Language", speakers: 0 },
  ];

  code2lang.sort((a, b) => b.speakers - a.speakers);

  const [yourNumber, setYourNumber] = useState("");
  const [theirNumber, setTheirNumber] = useState("");

  const [yourCountry, setYourCountry] = useState("");
  const [theirCountry, setTheirCountry] = useState("");

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const canStartCall = () => {
    const number1 = phone(yourNumber);
    const number2 = phone(theirNumber);
    return (
      number1.isValid &&
      number2.isValid &&
      yourCountry.length > 0 &&
      theirCountry.length > 0 &&
      checked
    );
  };

  const launchCall = async () => {
    const url = "https://translatemycall.com/api/twoway";
    const data = {
      number1: yourNumber,
      number1_lang: code2lang.find((obj) => obj.code === yourCountry).lang,
      number2: theirNumber,
      number2_lang: code2lang.find((obj) => obj.code === theirCountry).lang,
    };

    const params = Object.keys(data)
      .map((key) => key + "=" + data[key])
      .join("&");

    setLoading(true);
    await fetch(url + "?" + params, {
      mode: "no-cors",
    });
    setLoading(false);

    setYourNumber("");
    setTheirNumber("");
    setYourCountry("");
    setTheirCountry("");
    setChecked(false);
    setOpenModal(false);
  };

  Intercom({
    app_id: "ie7lg6j8",
    custom_launcher_selector: "#intercom_custom_launcher",
  });

  useEffect(() => {}, [
    yourNumber,
    theirNumber,
    yourCountry,
    theirCountry,
    checked,
  ]);

  return (
    <div className="flex flex-col items-center h-screen w-full bg-gradient-to-bl from-amber-500/50 to-50% dark:from-blue-950 dark:via-transparent">
      <div className="flex flex-col md:flex-row items-center justify-center w-full px-4 py-2 bg-black dark:bg-neutral-900">
        <p className="text-white dark:text-white">
          🎉 <b>We're now live!</b>
        </p>
        <a
          className="flex flex-row text-gray-300 ml-1 hover:underline"
          href="https://multilingual.com/translatemycall-com-launches-first-ai-powered-over-the-phone-interpretation-service/"
          target="_blank"
        >
          <i>Read our press release.</i>
          <svg
            viewBox="0 -6.5 36 36"
            version="1.1"
            className="h-4 ml-1 mt-1"
            fill="#ffffff"
            stroke="#ffffff"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <title>right-arrow</title> <desc>Created with Sketch.</desc>{" "}
              <g
                id="icons"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                {" "}
                <g
                  id="ui-gambling-website-lined-icnos-casinoshunter"
                  transform="translate(-212.000000, -159.000000)"
                  fill="#ffffff"
                  fill-rule="nonzero"
                >
                  {" "}
                  <g
                    id="square-filled"
                    transform="translate(50.000000, 120.000000)"
                  >
                    {" "}
                    <path
                      d="M187.108012,39.2902857 L197.649804,49.7417043 L197.708994,49.7959169 C197.889141,49.9745543 197.986143,50.2044182 198,50.4382227 L198,50.5617773 C197.986143,50.7955818 197.889141,51.0254457 197.708994,51.2040831 L197.6571,51.2479803 L187.108012,61.7097143 C186.717694,62.0967619 186.084865,62.0967619 185.694547,61.7097143 C185.30423,61.3226668 185.30423,60.6951387 185.694547,60.3080911 L194.702666,51.3738496 L162.99947,51.3746291 C162.447478,51.3746291 162,50.9308997 162,50.3835318 C162,49.8361639 162.447478,49.3924345 162.99947,49.3924345 L194.46779,49.3916551 L185.694547,40.6919089 C185.30423,40.3048613 185.30423,39.6773332 185.694547,39.2902857 C186.084865,38.9032381 186.717694,38.9032381 187.108012,39.2902857 Z M197.115357,50.382693 L186.401279,61.0089027 L197.002151,50.5002046 L197.002252,50.4963719 L196.943142,50.442585 L196.882737,50.382693 L197.115357,50.382693 Z"
                      id="right-arrow"
                    >
                      {" "}
                    </path>{" "}
                  </g>{" "}
                </g>{" "}
              </g>{" "}
            </g>
          </svg>
        </a>
      </div>

      <div className="flex flex-col h-full sm:w-full md:w-11/12 lg:4/5 items-center px-4">
        <div className="flex flex-row m-4 h-18 w-full justify-between items-center">
          <img className="h-16" src="/logo.svg" alt="TranslateMyCall" />
          <div className="hidden md:block relative">
            <button
              type="submit"
              className="md:w-32 py-3 px-4 justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-700 text-white hover:bg-blue-800 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none shadow-md"
              id="intercom_custom_launcher"
            >
              Talk to us
            </button>
          </div>
          <Modal
            open={openModal}
            setOpen={setOpenModal}
            launchCall={launchCall}
            loading={loading}
          />
        </div>

        <div className="flex flex-grow justify-center w-full items-center">
          <div className="max-w-[85rem] px-4 mx-auto">
            <div className="grid md:grid-cols-2 gap-8 lg:gap-12">
              <div className="md:mb-12 max-w-2xl">
                <h1 className="mb-4 font-semibold text-gray-800 text-4xl lg:text-5xl dark:text-neutral-200">
                  Your AI interpreter
                </h1>
                <p className="text-gray-600 dark:text-neutral-400">
                  TranslateMyCall translates phone calls from language to
                  language, like your own personal interpreter. Currently in
                  free public alpha testing;{" "}
                  <button
                    id="intercom_custom_launcher"
                    className="hover:underline text-blue-600 dark:text-blue-400"
                  >
                    please let us know
                  </button>{" "}
                  if you run into any issues!
                </p>

                <blockquote className="hidden md:block relative">
                  <footer className="mt-9">
                    <div className="space-y-6 lg:space-y-10">
                      <div className="flex gap-x-5 sm:gap-x-8">
                        <span className="shrink-0 inline-flex justify-center items-center size-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802"
                            />
                          </svg>
                        </span>
                        <div className="grow">
                          <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-neutral-200">
                            40 supported languages
                          </h3>
                          <p className="mt-1 text-gray-600 dark:text-neutral-400">
                            Pair any two languages together, from Arabic to
                            Vietnamese.
                          </p>
                        </div>
                      </div>

                      <div className="flex gap-x-5 sm:gap-x-8">
                        <span className="shrink-0 inline-flex justify-center items-center size-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                            />
                          </svg>
                        </span>
                        <div className="grow">
                          <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-neutral-200">
                            28 supported countries
                          </h3>
                          <p className="mt-1 text-gray-600 dark:text-neutral-400">
                            We support phone numbers from 28 countries, with
                            more coming soon.
                          </p>
                        </div>
                      </div>

                      <div className="flex gap-x-5 sm:gap-x-8">
                        <span className="shrink-0 inline-flex justify-center items-center size-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                          <svg
                            className="shrink-0 size-5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M7 10v12" />
                            <path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z" />
                          </svg>
                        </span>
                        <div className="grow">
                          <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-neutral-200">
                            Free while in alpha!
                          </h3>
                          <p className="mt-1 text-gray-600 dark:text-neutral-400">
                            TranslateMyCall.com is totally free while we are in
                            public alpha testing. Thank you for helping us work
                            out the kinks!
                          </p>
                        </div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>

              <div className="lg:max-w-lg lg:mx-auto lg:me-0 ms-auto mb-12">
                <div className="p-4 sm:p-7 flex flex-col bg-white rounded-2xl shadow-lg dark:bg-neutral-900">
                  <div className="text-center">
                    <h1 className="block text-3xl font-bold text-gray-800 dark:text-white">
                      Try for free!
                    </h1>
                  </div>

                  <div className="flex flex-col mt-8">
                    <div className="flex flex-col w-full">
                      <p className="text-gray-800">
                        Enter <b>your</b> number
                      </p>
                      <PhoneInput
                        onChange={setYourNumber}
                        className="mt-2 w-full custom-phone-input"
                        value={yourNumber}
                        onlyCountries={country_codes}
                        country="us"
                      />
                    </div>

                    <div className="flex flex-col w-full mt-2">
                      <p className="text-gray-800">
                        Select <b>your</b> language
                      </p>
                      <LanguageSelect
                        selected={yourCountry}
                        setSelected={setYourCountry}
                        options={code2lang}
                      />
                    </div>

                    <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>

                    <div className="flex flex-col w-full">
                      <p className="text-gray-800">
                        Enter <b>their</b> number
                      </p>
                      <PhoneInput
                        onChange={setTheirNumber}
                        className="mt-2 w-full custom-phone-input"
                        value={theirNumber}
                        onlyCountries={country_codes}
                        country="us"
                      />
                    </div>

                    <div className="flex flex-col w-full mt-2">
                      <p className="text-gray-800">
                        Select <b>their</b> language
                      </p>
                      <LanguageSelect
                        selected={theirCountry}
                        setSelected={setTheirCountry}
                        options={code2lang}
                      />
                    </div>
                  </div>

                  <div className="flex items-center mt-8">
                    <div className="flex">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                      />
                    </div>
                    <div className="ms-3">
                      <label
                        htmlFor="remember-me"
                        className="text-sm dark:text-white"
                      >
                        I agree that my phone calls may be reviewed by the
                        TranslateMyCall team to improve the service.
                      </label>
                    </div>
                  </div>

                  <div className="mt-8">
                    <button
                      className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-700 text-white hover:bg-blue-800 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none shadow-sm"
                      disabled={!canStartCall()}
                      onClick={() => setOpenModal(true)}
                    >
                      Launch call
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
