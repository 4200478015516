import React from "react";
import LoadingIcon from "./LoadingIcon";

export default function Modal({ open, setOpen, launchCall, loading }) {
  const handleClose = () => setOpen(false);

  if (!open) {
    return <></>;
  }

  return (
    <div
      id="hs-modal-upgrade-to-pro"
      className="fixed inset-0 z-[80] overflow-x-hidden overflow-y-auto flex items-center justify-center bg-black bg-opacity-50"
      role="dialog"
      tabIndex="-1"
      aria-labelledby="hs-modal-upgrade-to-pro-label"
    >
      <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-100 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
        <div className="bg-white border border-gray-200 rounded-xl shadow-sm pointer-events-auto dark:bg-neutral-900 dark:border-neutral-800">
          <div className="p-4 sm:p-7">
            <div className="text-center">
              <h3
                id="hs-modal-upgrade-to-pro-label"
                className="block text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200"
              >
                Before you start chatting...
              </h3>
            </div>

            {/* Modal Content */}
            <div className="mt-8 sm:mt-10 divide-y divide-gray-200 dark:divide-neutral-700">
              <div className="flex items-center gap-x-7 py-5 first:pt-0 last:pb-0">
                <img
                  src="https://www.svgrepo.com/show/505239/mute-notifications.svg"
                  width={48}
                  height={48}
                  alt="Mute notifications icon"
                />

                <div className="grow">
                  <h3 className="font-semibold text-gray-800 dark:text-neutral-200">
                    Disable <i>Do Not Disturb</i> mode.
                  </h3>
                </div>
              </div>

              <div className="flex items-center gap-x-7 py-5 first:pt-0 last:pb-0 ml-3">
                <img
                  src="https://www.svgrepo.com/show/535565/phone.svg"
                  width={24}
                  height={24}
                  alt="Phone icon"
                />

                <div className="grow">
                  <h3 className="font-semibold text-gray-800 dark:text-neutral-200 ml-3">
                    Watch for a call from 1-904 number.
                  </h3>
                </div>
              </div>

              <div className="flex items-center gap-x-7 py-5 first:pt-0 last:pb-0 ml-2">
                <img
                  src="https://www.svgrepo.com/show/522683/timer.svg"
                  width={36}
                  height={36}
                  alt="Timer icon"
                />

                <div className="grow">
                  <h3 className="font-semibold text-gray-800 dark:text-neutral-200 ml-1">
                    Once call starts, wait 2 seconds for translations.
                  </h3>
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="flex justify-end items-center gap-x-2 p-4 sm:px-7 dark:border-neutral-800">
            <button
              type="button"
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="py-2 w-36 flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
              onClick={launchCall}
            >
              {loading ? <LoadingIcon /> : "Launch Call"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
